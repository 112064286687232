import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SignInComponent from "./SignInComponent";

export default function SignPage() {
  return (
    <Container>
      <Logo>Administrator Doma+</Logo>
      <Content>
        <SignInComponent />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    min-height: 0;
    flex-direction: column;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;

  @media (max-width: 991px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Logo = styled.h1`
  font-size: 64px;

  @media (max-width: 991px) {
    margin-top: 32px;
    padding: 16px 64px 16px 64px;
  }
`;
